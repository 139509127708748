<template>
  <b-col
    v-if="!hiddenInput.transactionType"
    :lg="colTransactionType"
    class="px-2 mt-2 mt-lg-0"
  >
    <label class="font-weight-bold ft-s-14" for="transactionType">{{
      labelTransactionType
    }}</label>

    <v-select
      id="transactionType"
      v-model="transactionType"
      :options="transactionTypes"
      :loading="isFetching"
      :clearable="isNotDefaultValue(transactionType.label)"
      @input="onInput($event)"
    ></v-select>
  </b-col>
</template>

<script>
import vSelect from "vue-select";
import { TransectionType } from "../../models";

export default {
  props: {
    vModel: Object,

    defaultId: {
      type: [Number, String],
      default: null,
    },

    defaultValue: {
      type: String,
      default: "ทั้งหมด",
    },

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    colTransactionType: {
      type: Number,
      default: 3,
    },

    labelTransactionType: {
      type: String,
      default: "ตัวเลือกการขอเบิก",
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  components: {
    vSelect,
  },

  data() {
    return {
      isFetching: false,
      transactionType: {
        id: this.defaultId,
        label: this.defaultValue,
      },
    };
  },

  watch: {
    transactionType: "onTransactionTypeChanged",
  },

  computed: {
    form: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },

    transactionTypes() {
      return [
        {
          id: null,
          label: this.defaultValue,
        },
        ...TransectionType.all().map((x) => ({
          id: x.id,
          label: x.m_transaction_name,
        })),
      ];
    },
  },

  methods: {
    isNotDefaultValue(value) {
      return value !== this.defaultValue;
    },

    onInput(value) {
      if (!value) {
        this.transactionType = { id: this.defaultId, label: this.defaultValue };
      }
    },

    onTransactionTypeChanged(transactionType) {
      this.form = {
        ...transactionType,
      };
    },
  },

  async created() {
    this.isFetching = true;

    await TransectionType.api().findAll();

    this.isFetching = false;
  },
};
</script>
