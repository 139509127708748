<template>
  <page-content>
    <page-title heading="ข้อมูลแผนการสอน">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate" :disabled="!isOpenPlan"
          >เพิ่มแผนการเรียนการสอน</b-button
        >
      </template>
    </page-title>

    <filter-fiscal-year-term v-model="formFiscalYearTerm">
    </filter-fiscal-year-term>

    <filter-master
      v-if="authUser.isAdminType"
      v-model="filterMaster"
      :m-headquarter-id="
        !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
      "
      :m-division-id="
        !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
      "
      :m-station-id="
        !authUser.canAccessAllStations ? authUser.mStationId : null
      "
      :disabledInput="{
        headQuarter:
          !authUser.canAccessAllHeadQuarters &&
          authUser.mHeadquarterId !== null,
        division:
          !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
        station: !authUser.canAccessAllStations && authUser.mStationId !== null,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      :hidden-input="{ school: true, schoolClass: true, room: true }"
    >
    </filter-master>

    <filter-date-between
      :col-start-date="3"
      :col-end-date="3"
      class="mt-2"
      v-model="filterDateBetween"
    >
      <template #before>
        <b-col cols="12" lg="3" class="px-2">
          <label class="font-weight-bold ft-s-14" for="transactionStatus"
            >สถานะขอเบิกค่าตอบแทนการสอน</label
          >

          <v-select
            id="transactionStatus"
            v-model="transactionStatus"
            :clearable="transactionStatus !== defaultValue"
            :options="transactionStatuses"
            @input="onInput($event, 'transactionStatus')"
          ></v-select>
        </b-col>

        <filter-transaction-type
          v-model="filterTransactionType"
        ></filter-transaction-type>
        <!-- <b-col cols="12" lg="3" class="px-2">
        </b-col> -->
      </template>
    </filter-date-between>

    <div class="d-flex flex-row flex-wrap mt-3">
      <b-col sm="8" md="6" class="px-2">
        <b-form-group class="mb-0">
          <b-input-group class="input-group-seamless">
            <b-form-input
              :placeholder="
                authUser.isAdminType
                  ? 'ค้นหารายชื่อครูตำรวจแดร์หรือโรงเรียน'
                  : 'ค้นหารายชื่อโรงเรียน'
              "
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="2" class="px-2 mt-4 mt-sm-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </div>

    <teaching-dare-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="params"
      @edit="onEdit"
      @delete="onDelete"
      :showDelete="this.authUser.isSuperAdmin || this.authUser.isAdminAll ? true : false"
    />

    <create-or-update-lesson-plan
      :edit-data.sync="editData"
      v-model="showModalLessonPlan"
      @create:success="onCreateSuccess"
    ></create-or-update-lesson-plan>

    <base-delete
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></base-delete>
  </page-content>
</template>

<script>
import { sortBy } from "lodash";
import vSelect from "vue-select";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateLessonPlan from "../../components/modal/CreateOrUpdateLessonPlan";
import TeachingDareTable from "../../components/table/TeachingDare";
import { Auth, TeachingPlan, User, ConfigTeachingDare } from "../../models";
import BaseDelete from "../../components/modal/BaseDelete";
import FilterMaster from "../../components/form/FilterMaster";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import FilterTransactionType from "../../components/form/FilterTransactionType";

const defaultValue = "ทั้งหมด";

export default {
  components: {
    vSelect,
    PageContent,
    PageTitle,
    CreateOrUpdateLessonPlan,
    TeachingDareTable,
    BaseDelete,
    FilterMaster,
    FilterDateBetween,
    FilterFiscalYearTerm,
    FilterTransactionType,
  },

  props: {
    user: {
      type: User,
    },
  },

  data() {
    return {
      defaultValue,
      isOpenPlan: false,
      filterMaster: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      filterTransactionType: {
        id: null,
        label: defaultValue,
      },
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalLessonPlan: false,
      showModalDelete: false,
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
      transactionStatus: defaultValue,
      transactionStatuses: [defaultValue, "ขอเบิกแล้ว", "ยังไม่ได้ขอเบิก"],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      let fields = [{ key: "station", label: "สถานี", sortable: true }];

      if (this.authUser.isAdminType) {
        fields.push({
          key: "user_name",
          label: "ชื่อ-นามสกุล",
          sortable: true,
        });
      }

      fields = [
        ...fields,
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "m_school.m_amphur.m_amphur_name", label: "อำเภอ" },
        // { key: "m_school.m_province.m_province_name", label: "จังหวัด" },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        // {
        //   key: "teaching_plan_count",
        //   label: "แผนการสอน",
        //   class: "text-center",
        // },
        {
          key: "transactionNumber",
          label: "หมายเลขการเบิก",
          sortable: true,
        },
        {
          key: "transactionTypeName",
          label: "ประเภทการเบิก",
          sortable: true,
        },
        {
          key: "created_at",
          label: "วันที่สร้าง",
          sortable: true,
        },
      ];

      return fields;
    },

    params() {
      const {
        filterMaster: params,
        filterDateBetween: dateParams,
        formfilter,
        filterTransactionType,
      } = this;

      let additionalParams = {};

      if (!this.authUser.isAdminType) {
        this.$set(params, "userProfileId", this.authUser.id);
      }

      let hasTransaction = null;

      if (this.transactionStatus && this.transactionStatus !== defaultValue) {
        if (this.transactionStatus === "ยังไม่ได้ขอเบิก") {
          hasTransaction = 0;
        }

        if (this.transactionStatus === "ขอเบิกแล้ว") {
          hasTransaction = 1;
        }
      }

      if (filterTransactionType && filterTransactionType.id !== null) {
        this.$set(
          additionalParams,
          "mTransactionTypeId",
          filterTransactionType.id
        );
      }

      // console.log(this.authUser)

      return {
        ...params,
        ...dateParams,
        ...formfilter,
        ...this.getFilterTermYear(),
        hasTransaction,
        ...additionalParams,
      };
    },
  },

  methods: {
    async fechTeachingDare() {
      try {
        const promise = await ConfigTeachingDare.api().findAll({ save: false });

        let { data: responseData = {} } =
          promise.response.data;
        
        this.isOpenPlan = responseData.is_open;
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      }
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalLessonPlan = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      if (item.m_station) {
        this.$set(this.editData, "mDivisionId", item.m_station.mDivisionId);

        if (item.m_station.m_division) {
          this.$set(
            this.editData,
            "mHeadquarterId",
            item.m_station.m_division.mHeadquarterId
          );
        }
      }

      if (item.m_school) {
        this.$set(this.editData, "mProvinceId", item.m_school.mProvinceId);
        this.$set(this.editData, "mAmphurId", item.m_school.mAmphurId);
      }

      const promise = await TeachingPlan.api().findAll({
        teachingDareId: item.id,
      });

      const { data = [] } = promise.response.data;

      if (data.length) {
        this.$set(
          this.editData,
          "teaching_plans",
          sortBy(data, "createdAt").map(
            ({ lesson_number, lesson_name, date, time, content }) => ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
            })
          )
        );
      }

      this.showModalLessonPlan = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },

    onInput(value, dataName) {
      if (!value) {
        this.$set(this, dataName, this.defaultValue);
      }
    },
  },

  created() {
    this.fechTeachingDare();
  },
};
</script>
